<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link>
      <b-img :src="brand" alt="Nectios logo" class="brand-logo brand" />
    </b-link>
    <b-card v-if="errorType === 'unauthorized-user'" class="mt-5">
      <feather-icon
        size="30"
        icon="AlertTriangleIcon"
        class="d-block mx-auto text-danger my-1"
      />
      <h2 class="text-center text-danger mb-2">
        {{ $t("backoffice.settings.restricted-area") }}
      </h2>
      <img
        :src="imgUrl"
        alt="Error page"
        style="height: 65vh"
        class="d-block mx-auto"
      />
    </b-card>
    <div v-else class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t("404.title") }}
        </h2>
        <i18n :path="'404.requested-url'" tag="p" class="mb-2">
          <a v-if="failedUrl" :href="failedUrl" target="_blank">{{
            failedUrl
          }}</a>
          <span v-else>
            {{ $t("404.requested-url-placeholder") }}
          </span>
        </i18n>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :href="exploreLocation"
        >
          {{ $t("404.see-communities") }}
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
import { BLink, BButton, BImg } from "bootstrap-vue";
import ErrorDarkPlaceholder from "@/assets/images/pages/error-dark.svg";
import ErrorPlaceholder from "@/assets/images/pages/error.svg";
import BrandLogo from "@/assets/images/brand/brand-logo.svg";
import BrandFavicon from "@/assets/images/brand/brand-favicon.png";
import store from "@/store/index";
import UnauthorizedPlaceholder from "@/assets/images/placeholders/light/acceso_restringido.svg";

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: ErrorPlaceholder,
      unauthorizedImg: UnauthorizedPlaceholder,
      from: null,
    };
  },
  computed: {
    exploreLocation() {
      const myNectiosUrl = process.env.VUE_APP_MY_NECTIOS_URL;
      if (myNectiosUrl) {
        return myNectiosUrl;
      }

      const mainCommunity = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        "app"
      ).replace("{slug}", "");
      return `${mainCommunity}`;
    },
    errorType() {
      return this.$route.params.error;
    },
    brand() {
      return BrandLogo;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return ErrorDarkPlaceholder;
      }
      if (this.errorType === "unauthorized-user") {
        return this.unauthorizedImg;
      } else {
        return this.downImg;
      }
    },
    failedUrl() {
      if (!this.from || !this.from.name) {
        return "";
      }

      return `${document.location.protocol}//${document.location.host}${this.from.path}`;
    },
    currentCollectiveSlug() {
      // console.log('this.from', this.from)
      // if (!this.from || !this.from.name) {
      //   return "";
      // }
      return this.$route?.params?.communityId;
      // return this.from.params?.communityId;
    },
  },
  created() {
    // console.log('currentCollectiveSlug', this.currentCollectiveSlug);
    if(this.currentCollectiveSlug === 'error'){
      // console.log('Error.vue - Error is not a valid community slug, redirecting to main root... ');
      this.$router.push('/');
    }
    if(this.currentCollectiveSlug === 'undefined'){
      // console.log('Error.vue - Undefined is not a valid community slug, redirecting to main root... ');
      this.$router.push('/');
    }
    const links = document.querySelectorAll("link[rel~='icon']");
    for (let index = 0; index < links.length; index += 1) {
      const link = links[index];
      link.href = BrandFavicon;
    }
    /* if (this.errorType !== '404') setTimeout(() => this.exploreLocation(), 3000); */
  },
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-param-reassign
    next((vm) => {
      vm.from = from;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand {
  height: 50px;
}
</style>
